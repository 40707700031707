export default {
  methods: {
    getDashboardOverview(params) {
      return $http.get('/dashboard/dashboard-overview', { params });
    },
    getChannelsOverview(params) {
      return $http.get('/dashboard/channels-overview', { params });
    },
  },
};
