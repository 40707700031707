<template>
  <div class="w-100">
    <page-header title="Main Dashboard" />
    <div class="d-flex">
      <!-- LEFT COLUMN -->
      <div>
        <!-- VISITORS -->
        <v-card class="overview-card mb-6" color="#FFA521">
          <div class="d-flex flex-column align-start fill-height">
            <v-spacer />
            <img src="/img/visitors.svg" />
            <div class="fs-16 fw-800 dark-text">VISITORS</div>
            <v-spacer />
            <a class="fs-12 fw-600 dark-text" v-if="false">Go to Visitor Profile</a>
          </div>
          <v-spacer />
          <div class="text-right">
            <div class="fs-24 fw-600 white--text">
              {{ totalVisitors | numberFormatter }}
            </div>
            <div class="fs-12 fw-800 dark-text">Total Visitors</div>
            <v-divider class="mt-3 mb-2" />
            <div class="fs-24 fw-600 white--text">
              {{ newVisitors | numberFormatter }}
            </div>
            <div class="fs-12 fw-800 dark-text">New Visitors</div>
          </div>
        </v-card>
        <!-- BUYERS -->
        <v-card class="overview-card mb-6" color="#DB8518">
          <div class="d-flex flex-column align-start fill-height">
            <v-spacer />
            <img src="/img/buyers.svg" />
            <div class="fs-16 fw-800 dark-text">BUYERS</div>
            <v-spacer />
            <a class="fs-12 fw-600 dark-text" v-if="false">Go to Buyer Profile</a>
          </div>
          <v-spacer />
          <div class="text-right">
            <div class="fs-24 fw-600 white--text">
              {{ totalBuyers | numberFormatter }}
            </div>
            <div class="fs-12 fw-800 dark-text">Total Buyers</div>
            <v-divider class="mt-3 mb-2" />
            <div class="fs-24 fw-600 white--text">
              {{ newBuyers | numberFormatter }}
            </div>
            <div class="fs-12 fw-800 dark-text">New Buyers</div>
          </div>
        </v-card>
        <!-- SALES -->
        <v-card class="overview-card" color="#B76712">
          <div class="d-flex flex-column align-start fill-height">
            <v-spacer />
            <img src="/img/sales.svg" />
            <div class="fs-16 fw-800 dark-text">SALES</div>
            <v-spacer />
            <a class="fs-12 fw-600 dark-text" @click="$router.replace('/sale-overview')">
              Go to Sale Overview
            </a>
          </div>
          <v-spacer />
          <div class="text-right">
            <div class="d-flex justify-end fs-24 fw-600 white--text">
              <div class="mt-1 fs-16 fw-700">$</div>
              {{ totalSales | numberFormatter }}
            </div>
            <div class="fs-12 fw-800 dark-text">Total Sales</div>
            <v-divider class="mt-3 mb-2" />
            <div class="fs-24 fw-600 white--text">
              {{ totalOrders | numberFormatter }}
            </div>
            <div class="fs-12 fw-800 dark-text">Total Orders</div>
          </div>
        </v-card>
      </div>
      <!-- MIDDLE COLUMN -->
      <div class="position-relative flex-1 d-flex align-end px-6">
        <!-- CHANNEL CHART -->
        <div class="channelChartWrapper">
          <div id="channelChartContainer"></div>
          <div class="channelChartHelper" v-if="!totalVisitors">
            <help-tooltip>Data will not be available until tracking is configured.</help-tooltip>
          </div>
        </div>
        <!-- CHANNELS -->
        <v-card class="channel-card lighten-1" color="#1AC0C6" dark>
          <div class="d-flex">
            <img src="/img/channels.svg" />
            <div class="ml-2 fs-16 fw-800">CHANNELS</div>
            <v-spacer />
            <help-tooltip color="#FFF" v-if="!totalVisitors">
              Data will not be available until tracking is configured.
            </help-tooltip>
            <!-- <a class="fs-12 fw-600" v-if="false">See the Channels Page</a> -->
          </div>
          <div class="d-flex align-center mt-8">
            <div style="width: 200px" class="fs-14 fw-700">Channel with most followers</div>
            <v-sparkline
              style="height: 75px"
              class="w-100 px-6"
              :padding="24"
              fill
              color="rgba(255, 255, 255, .7)"
              stroke-linecap="round"
              smooth
              auto-draw
              :value="socialVisitors"
            />
            <div>
              <div class="fs-14 fw-700">Facebook</div>
              <div class="fs-36 fw-600">{{ totalFacebookVisitors }}</div>
            </div>
            <div class="pl-12">
              <div class="d-flex align-center">
                <div style="width: 140px" class="fs-10 fw-700">Visitors from Social Media</div>
                <v-sparkline
                  class="w-100"
                  :padding="24"
                  fill
                  color="rgba(255, 255, 255, .7)"
                  stroke-linecap="round"
                  smooth
                  auto-draw
                  :value="socialVisitors"
                />
                <div class="fs-20 fw-700">{{ totalSocialVisitors }}</div>
              </div>
              <v-divider />
              <div class="d-flex align-center">
                <div style="width: 140px" class="fs-10 fw-700">Buyers from Social Media</div>
                <v-sparkline
                  class="w-100"
                  :padding="24"
                  fill
                  color="rgba(255, 255, 255, .7)"
                  stroke-linecap="round"
                  smooth
                  auto-draw
                  :value="socialBuyers"
                />
                <div class="fs-20 fw-700">{{ totalSocialBuyers }}</div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <!-- RIGHT COLUMN -->
      <div class="d-flex flex-column">
        <!-- BEST SELLING PRODUCT -->
        <v-card class="product-card">
          <div class="d-flex align-center w-100">
            <img src="/img/product.svg" height="16" />
            <div style="color: #3e89a8" class="ml-2 fs-16 fw-700">PRODUCTS</div>
            <v-spacer />
            <help-tooltip v-if="!bestProduct">
              Data will not be available until tracking is configured.
            </help-tooltip>
          </div>
          <div style="color: #3e89a8" class="mt-4 fs-18 fw-600">Best Selling Product</div>
          <img
            class="mt-2"
            :src="bestProduct.imageUrl || defaultProductImageUrl"
            height="100"
            v-if="bestProduct"
          />
          <div class="text-center" v-if="bestProduct">
            <div style="color: #727272">{{ bestProduct.title }}</div>
            <div style="color: #9fa8da" class="fs-10">#{{ bestProduct.productId }}</div>
            <div class="mt-2 fs-12 fw-800">
              <div style="color: #5f5f5f">
                {{ bestProduct.salesCount | numberFormatter }} buyers
              </div>
              <div style="color: #5f5f5f">${{ bestProduct.salesPrice | numberFormatter }}</div>
            </div>
          </div>
        </v-card>
        <!-- TOP PRODUCTS -->
        <v-card dark class="top-products-card bottom-card" color="#3E89A8">
          <div class="mt-8 fs-18 fw-700">Top 5 Products</div>
          <table class="mt-4 w-100">
            <tr class="fs-12 fw-600" v-for="topProduct in topProducts" :key="topProduct.productId">
              <td class="product-title">
                {{ topProduct.title | textEllipsis(18) }}
              </td>
              <td class="text-right">
                {{ topProduct.salesCount | numberFormatter }}
              </td>
              <td class="text-right">${{ topProduct.salesPrice | numberFormatter }}</td>
            </tr>
          </table>
          <v-spacer />
          <a class="mt-4 fs-12 fw-600 white--text" @click="$router.replace('/products')">
            See more information in the Products Page
          </a>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGaugeModule from 'highcharts/modules/solid-gauge';
import HelpTooltip from '@/components/App/HelpTooltip.vue';
import PageHeader from '@/components/App/PageHeader.vue';
import DashboardService from '@/services/DashboardService';

HighchartsMore(Highcharts);
HighchartsSolidGaugeModule(Highcharts);

export default {
  name: 'DashboardPage',
  mixins: [DashboardService],
  components: {
    HelpTooltip,
    PageHeader,
  },
  data: () => ({
    totalVisitors: 0,
    newVisitors: 0,
    totalBuyers: 0,
    newBuyers: 0,
    totalSales: 0,
    totalOrders: 0,
    topProducts: [],
    bestProduct: null,
    totalFacebookVisitors: 0,
    totalInstagramVisitors: 0,
    totalGoogleVisitors: 0,
    totalOrganicSearchVisitors: 0,
    totalDirectLinkVisitors: 0,
    socialVisitors: [],
    totalSocialVisitors: 0,
    socialBuyers: [],
    totalSocialBuyers: 0,
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.updateChannelChart();
      this.fetchDashboardOverview();
      this.fetchChannelsOverview();
    },
    async fetchDashboardOverview() {
      const params = { dateRange: this.fullDateRange };
      const data = await this.getDashboardOverview(params);
      this.totalVisitors = data.totalVisitors;
      this.newVisitors = data.newVisitors;
      this.totalBuyers = data.totalBuyers;
      this.newBuyers = data.newBuyers;
      this.totalSales = data.totalSales;
      this.totalOrders = data.totalOrders;
      this.topProducts = data.topProducts;
      this.bestProduct = data.bestProduct;
    },
    async fetchChannelsOverview() {
      const params = { dateRange: this.fullDateRange };
      const data = await this.getChannelsOverview(params);

      this.totalFacebookVisitors = data.visitorsData.reduce(
        (total, item) => total + (item['(facebook)'] || 0),
        0,
      );

      this.totalInstagramVisitors = data.visitorsData.reduce(
        (total, item) => total + (item['(instagram)'] || 0),
        0,
      );

      this.totalGoogleVisitors = data.visitorsData.reduce(
        (total, item) => total + (item['(google)'] || 0),
        0,
      );

      this.totalOrganicSearchVisitors = data.visitorsData.reduce(
        (total, item) => total + (item['(organic search)'] || 0),
        0,
      );

      this.totalDirectLinkVisitors = data.visitorsData.reduce(
        (total, item) => total + (item['(direct)'] || 0),
        0,
      );

      const visitorsData = data.visitorsData.reduce((history, item) => {
        history[item.date] = item['(social)'];
        return history;
      }, {});
      this.socialVisitors = this.chartDateRange.map(date => visitorsData[date] || 0);
      this.totalSocialVisitors = this.socialVisitors.reduce((total, count) => total + count, 0);

      const buyersData = data.buyersData.reduce((history, item) => {
        history[item.date] = item.buyers;
        return history;
      }, {});
      this.socialBuyers = this.chartDateRange.map(date => buyersData[date] || 0);
      this.totalSocialBuyers = this.socialBuyers.reduce((total, count) => total + count, 0);

      this.updateChannelChart();
    },
    updateChannelChart() {
      const countMap = {
        'Direct Link': this.totalDirectLinkVisitors,
        'Organic Search': this.totalOrganicSearchVisitors,
        Google: this.totalGoogleVisitors,
        Instagram: this.totalInstagramVisitors,
        Facebook: this.totalFacebookVisitors,
      };
      const background = _.range(0, 5).map(index => ({
        outerRadius: `${80 - index * 10}%`,
        innerRadius: `${77 - index * 10}%`,
        backgroundColor: '#E3DEF733',
        borderWidth: 0,
      }));
      const series = [
        {
          label: 'Direct Link',
          color: '#68C2C5',
        },
        {
          label: 'Organic Search',
          color: '#95BF47',
        },
        {
          label: 'Google',
          color: '#37A859',
        },
        {
          label: 'Instagram',
          color: '#F6640E',
        },
        {
          label: 'Facebook',
          color: '#006AFF',
        },
      ].map((serie, index) => ({
        name: serie.label,
        data: [
          {
            color: serie.color,
            radius: `${40 + index * 10}%`,
            innerRadius: `${37 + index * 10}%`,
            y: (countMap[serie.label] * 100) / this.totalVisitors || 0,
          },
        ],
      }));
      Highcharts.chart(
        'channelChartContainer',
        {
          chart: {
            type: 'solidgauge',
            height: '100%',
            backgroundColor: 'transparent',
            spacing: 0,
          },
          credits: {
            enabled: false,
          },
          title: {
            text: undefined,
          },
          tooltip: {
            borderWidth: 0,
            backgroundColor: '#1BC0C6',
            shadow: false,
            pointFormatter() {
              return `<span style="font-size: 14px; font-weight: 700; color: #FFF;">${
                this.series.name
              }: ${countMap[this.series.name]} visitors</span>`;
            },
            followPointer: true,
          },
          pane: {
            startAngle: 180,
            endAngle: 360,
            size: '100%',
            background: [
              {
                outerRadius: '100%',
                innerRadius: '90%',
                backgroundColor: {
                  linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1,
                  },
                  stops: [
                    [0, '#DDECED'],
                    [1, '#DDECED00'],
                  ],
                },
                borderWidth: 0,
              },
              {
                outerRadius: '90%',
                innerRadius: '80%',
                backgroundColor: '#FFF',
                borderWidth: 0,
              },
              ...background,
            ],
          },
          yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: [],
          },
          plotOptions: {
            solidgauge: {
              dataLabels: {
                enabled: false,
              },
              rounded: true,
            },
          },
          series,
        },
        chart => {
          series.forEach((serie, index) => {
            chart.renderer
              .text(`${serie.name}: ${Math.round(serie.data[0].y)}%`, 208, 280 + index * 20)
              .css({ fontWeight: 700, color: '#095473' })
              .add()
              .toFront();
          });
        },
      );
    },
  },
  watch: {
    dateRange(newValue, oldValue) {
      if (oldValue.length === 1 && newValue.length === 2) {
        this.refresh();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-card {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  width: 320px;
  height: 160px;

  ::v-deep {
    .v-divider {
      border-color: #ffffff;
    }
  }

  .dark-text {
    color: #3f3f3f;
  }
}
.channelChartWrapper {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 1;
  width: 400px;
  height: 400px;

  #channelChartContainer {
    position: relative;
  }

  .channelChartHelper {
    position: absolute;
    left: calc(50% - 8px);
    top: calc(50% - 8px);
    z-index: 100;
  }
}
.channel-card {
  flex: 1;
  height: 160px;
  padding: 16px 24px;
}
.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 320px;
  min-height: 300px;
  z-index: 1;
}
.top-products-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 32px 32px 16px;
}
</style>
